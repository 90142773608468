.line-chart {
  /* width: calc(100% - 60px); */
  background: #fff;
  border-radius: 8px;
  border: 0.5px solid #e1e8ef;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
  padding: 30px;
  margin-bottom: 15px;
}

.line-chart:hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}

.line-chart:not(hover) {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}

.chart {
  height: 300px;
  width: 100%;
  font-size: 14px;
  color: #A2A3A5;
}

.progress-bar-container {
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 30px;
  border: 0.5px solid #e1e8ef;
  width: 100%;
}
.progress-bar-container:hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}

.progress-bar-container:not(hover) {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}
.progress-bar-inner-container {
  width: 100%;
}
/* .title {
  position: absolute;
  font-weight: 600;
  font-size: 20px;
} */
.progress-bar {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.progress-bar-top {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.progress-all-value {
  font-weight: bold;
  font-size: 30px;
  color: #11263c;
}
.progress-all-text {
  margin-left: 10px;
  font-size: 16px;
  color: #d0d1d2;
}
.progress-bar-background {
  width: 100%;
  height: 12px;
  background: #cbe6ff;
  border-radius: 100px;
  margin-bottom: 15px;
}
.progress-bar-completed {
  width: 50%;
  height: 100%;
  background: #338de0;
  border-radius: 100px;
}
.progress-bar-bottom {
  display: grid;
  grid-template-columns: 50px 150px 50px 150px;
  align-items: center;
}
.completed-line {
  background: #338de0;
  border-radius: 100px;
  height: 5px;
  width: 40px;
}

.new-line {
  background: #cbe6ff;
  border-radius: 100px;
  height: 5px;
  width: 40px;
}

.text {
  font-size: 16px;
  color: #d0d1d2;
}

.value {
  font-weight: bold;
  font-size: 16px;
  color: #11263c;
}

.chart-container {
  background: #fff;
  border-radius: 8px;
  border: 0.5px solid #e1e8ef;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
  padding: 30px;
  padding-top: 80px;
  display: flex;
}

.chart {
  /* width: 45%; */
  /* height: 250px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart-container:hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}

.chart-container:not(hover) {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}

.chartdiv {
  height: 250px;
  width: 250px;
  background: #f2f7ff;
  border-radius: 50%;
}

.title {
  position: absolute;
  margin-left: 30px;
  margin-top: 30px;
  font-weight: 600;
  font-size: 20px;
  color: #080a1c;
}

.chart-text {
  position: absolute;
  font-weight: bold;
  font-size: 24px;
  color: #080a1c;
}

.chart-text-all {
  position: absolute;
  margin-top: 50px;
  color: #a0a0a0;
  font-size: 13px;
}

.chart-values {
  display: grid;
  grid-template-columns: 50px 150px;
  align-items: center;
  margin-left: 30px;
  grid-auto-rows: 30px;
  align-self: center;
}

.chart-values-title {
  font-size: 16px;
  color: #a0a0a0;
  margin-left: 10px;
}

.chart-values-value {
  font-weight: bold;
  color: #080a1c;
  font-size: 16px;
  margin-left: 10px;
}

.first-line {
  background: #338de0;
  border-radius: 100px;
  height: 5px;
}

.second-line {
  background: #82bff6;
  border-radius: 100px;
  height: 5px;
}

.third-line {
  background: #cbe6ff;
  border-radius: 100px;
  height: 5px;
}

.two-charts {
  display: flex;
  width: 100%;
  gap: 24px;
}

.card {
  display: flex;
  flex-direction: column;
  height: 183px!important;
  width: 325px;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border: 0.5px solid #E1E8EF;
  background: white;
}

.card:hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
  transition: .5s;
}

.card:not(hover) {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: .5s;
}

.task-name {
  height: 38px;
  margin-bottom: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  color: #121F3E;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.progress {
  width: 100%;
  background: #E7EDF2;
  height: 9px;
  border-radius: 30px;
  margin-bottom: 7px;
}

.bar-low {
  width: 60%;
  height: 9px;
  background: #28C397;
  border-radius: 30px;
}

.bar-medium {
  width: 60%;
  height: 9px;
  background: #FCC865;
  border-radius: 30px;
}

.bar-high {
  width: 60%;
  height: 9px;
  background: #FF575F;
  border-radius: 30px;
}

.percentage {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.passed {
  font-size: 14px;
  color: #96A0B5;
}

.percent {
  font-weight: 500;
  font-size: 16px;
  color: #121F3E;
}

.deadline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.low {
  width: 64px;
  height: 32px;
  border-radius: 8px;
  background: #E9F9F5;
  color: #28C397;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.medium {
  width: 64px;
  height: 32px;
  border-radius: 8px;
  background: #FFF8EC;
  color: #FCC865;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.high {
  width: 64px;
  height: 32px;
  border-radius: 8px;
  background: #FFEEEF;
  color: #FF575F;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.date {
  color: #96A0B5;
  font-weight: 500;
  font-size: 14px;
}

.details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.details-right {
  display: flex;
  flex-direction: row;
}

.avatar {
  display: flex;
  width: 32px;
  height: 32px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #FFFFFF;
}

.avatar img {
  object-fit: 'cover';
  width: 32px;
  height: 32px;
}

.left {
  margin-left: -15px;
}

.details-left {
  display: flex;
}

.comments {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: #121F3E;
  cursor: pointer;
  border: none;
}

.comments:hover {
  fill: #338DE0;
  color: #338DE0;
  transition: .3s;
}

.comments:hover .comments-svg {
  fill: #338DE0;
  color: #338DE0;
  transition: .3s;
}

.comments:not(hover) {
  fill: #96A0B5;
  color: #121F3E;
  transition: .3s;
}

.comments:not(hover) .comments-svg {
  fill: #96A0B5;
  color: #121F3E;
  transition: .3s;
}

.passed-tasks {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: #121F3E;
  margin-left: 10px;
  cursor: pointer;
}
.passed-tasks:hover {
  fill: #338DE0;
  color: #338DE0;
  transition: .3s;
}

.passed-tasks:hover .passed-tasks-svg {
  fill: #338DE0;
  color: #338DE0;
  transition: .3s;
}

.passed-tasks:not(hover) {
  fill: #96A0B5;
  color: #121F3E;
  transition: .3s;
}

.passed-tasks:not(hover) .passed-tasks-svg {
  fill: #96A0B5;
  color: #121F3E;
  transition: .3s;
}

.attached-docs {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
  color: #121F3E;
  cursor: pointer;
}

.attached-docs:hover {
  fill: #338DE0;
  color: #338DE0;
  transition: .3s;
}

.attached-docs:hover .attached-docs-svg {
  fill: #338DE0;
  color: #338DE0;
  transition: .3s;
}

.attached-docs:not(hover) {
  fill: #96A0B5;
  color: #121F3E;
  transition: .3s;
}

.attached-docs:not(hover) .attached-docs-svg {
  fill: #96A0B5;
  color: #121F3E;
  transition: .3s;
}

.employee-card {
  min-height: 40px;
  width: 335px;
  margin-bottom: 5px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 0 15px;
  border: 0.5px solid #e1e8ef;
}

.employee-card:hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}

.employee-card:not(hover) {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}

.employee-card b {
  font-weight: 600;
  font-size: 16px;
  width: 30px;
  color: #121f3e;
}

.employee-card .avatar {
  display: flex;
  border-radius: 50%;
  overflow: hidden;
}

.employee-card .avatar img {
  object-fit: 'cover';
  width: 32px;
  height: 32px;
}

.employee-card p {
  margin-left: 20px;
  color: #121f3e;
  font-size: 16px;
  background: none!important;
}

.cards-panel {
  display: flex;
  justify-content: space-between;
}

.rgb-panel {
  display: grid;
  grid-template-rows: 180px 180px 180px;
  align-items: center;
  justify-content: center;
  width: 228px;
}

.rgb {
  margin-bottom: 14px;
  width: 228px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  border-radius: 8px;
  border: 0.5px solid #e1e8ef;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.rgb:hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}

.rgb:not(hover) {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}

.rgb-img {
  align-self: center;
}

.rgb-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-left: 30px;
}

.rgb p {
  align-self: flex-start;
  font-size: 16px;
}

.rgb b {
  align-self: flex-start;
  font-size: 30px;
}

.companies {
  background: #fff;
  color: #ff575f;
}

.projects {
  background: #fff;
  color: #28c397;
}

.tasks {
  background: #fff;
  color: #338de0;
}

.todo-panel {
  width: 385px;
  margin-left: 30px;
  padding-top: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.todo-panel p {
  background: rgba(231, 237, 242, 0.7);
  border-radius: 8px;
  text-align: center;
  padding: 10px;
  color: #121f3e;
  font-size: 16px;
  margin-bottom: 10px;
  width: 347px;
  margin-right: 5px;
}

.todo-cards {
  display: flex;
  flex-direction: column;
  height: 470px;
  width: 385px;
  overflow-y: auto;
  align-items: center;
  padding-top: 5px;
}

.top-employees {
  width: 385px;
  margin-left: 30px;
  padding-top: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-employees p {
  background: rgba(231, 237, 242, 0.7);
  border-radius: 8px;
  text-align: center;
  padding: 10px;
  color: #121f3e;
  font-size: 16px;
  margin-bottom: 10px;
  width: 347px;
  margin-right: 5px;
}

.employee-cards {
  display: flex;
  flex-direction: column;
  height: 470px;
  width: 385px;
  overflow-y: auto;
  align-items: center;
  padding-top: 5px;
}

.visualisation {
  width: calc(100% - 30px );
  height: 50px;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border: 0.5px solid #e1e8ef;
  font-weight: 600;
  font-size: 20px;
  color: #121f3e;
  margin-bottom: 10px;
}

.visualisation:hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}

.visualisation:not(hover) {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}

.visualisation:hover .chevron-right {
  fill: #338de0;
  transition: 0.5s;
}

.visualisation:not(hover) .chevron-right {
  fill: #121f3e;
  transition: 0.5s;
}
.chevron-right {
  margin-left: 20px;
}