// .ant-list-item-meta-avatar {

//   .ant-avatar-image {
//     width: 80px;
//     height: 80px;
//   }
// }
// .ant-comment {
//   background-color: transparent;
// }
// .comment-list li{
//   background: rgba(51, 141, 224, 0.08);
// border: 1px solid #F2F6FF;
// box-sizing: border-box;
// border-radius: 8px;
// width: 840px;
// margin: 20px;
// padding: 15px;
// .ant-comment-content-detail{
//   text-align: start;
// }
// }
// .ant-comment-content-author span {
//   font-weight: 500;
// font-size: 16px;
// line-height: 19px;
// /* identical to box height */

// letter-spacing: 0.15px;
// }
// .ant-list-items li li {
//   margin: 0;
//   padding: 0;
//   width: 540px;
// }
// .reply{
 

// }
.comments{

    border-radius: 8px!important;
    padding: 13px 15px;
    line-height: 20px;
    min-height: 48px!important;
    margin: 0!important;
    width: 100%;
    outline: none;
    border: 1px solid #E1E8EF;
    transition: all 2ms ease;
    &:focus{
      border: 1px solid #338DE0;
    
    }
  
}


.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 0;
}

.ant-list-split .ant-list-item {
  border-bottom: 1px solid #C6D1DD;
}

.ant-list-item-meta-content {
  display: inherit;
}

.ant-list-item-meta-description {
  width: 100%;
}

.taskDescription {
  // margin-left: 50px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.taskItem {
  color: #338DE0;
  text-align: left;
}

.ant-list-item-meta-title {
  width: 40%;
}

.ant-collapse-header {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.projectCard {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
  border-radius: 3px;
  font-size: 16px;
}

.projectCard h4 {
  font-size: 16px;
  // font-family: SFProDisplay-Regular, sans-serif;
  font-weight: normal;
  // font-weight: 500;
  color: #96A0B5;
}

.projectCard h3:not(:first-child) {
  font-size: 20px;
  margin: 20px 0;
}
.line {
  height: 1px;
  background: #C6D1DD;
  margin: 20px 0;
}
.members .member {
  margin-bottom: 20px;
}
.members .member .position {
  background: #F2F7FF;
  color: #3C4E77;
  // font-family: SFProDisplay-Bold, sans-serif;
  font-weight: bold;
  border-radius: 3px;
  padding: 12px 18px;
}
.members .member .position-value {
  padding: 12px 18px 0;
}
.members .member .position-action {
  padding: 0 18px;
}

.myProjectsButtons_edit {
  border: none;
}

.description-overflow.folded {
  max-height: 200px;
}
.link-action {
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  color: #1890FF;
  font-size: 16px;
}
.link-action .rotate {
  transform: rotate(180deg);
}

.myProjectsButtons_edit :hover {


}
// .property {
//   margin-bottom: 1px;
// }
.property header {
  display: flex;
  justify-content: space-between;
  align-items: center;
} 

.myProjectsButtons_delete {
  border: none;
}

.myProjectsButtons_delete svg:hover {
  fill: green;
}

.deletesvg svg:hover {
  fill: green;
  color: green;
  fill-rule: evenodd;
}

.projectBlockOnMap {
  text-align: left;
  margin-bottom: 40px;

  h3 {
    font-size: 20px;
  }

  h4 {
    // font-family: SFProDisplay-Medium, sans-serif;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin: 10px 0;
    width: 100%;

    small {
      color: #A0A0A0;
      font-size: 14px;
      // font-family: SFProDisplay-Regular, sans-serif;
      font-weight: normal;
    }
  }
}

.ant-card-body {
  padding: 0;
}

.projectDecs {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

pre {
  max-width: 100%;
  // font-family: 'SFProDisplay-Regular', sans-serif;
  font-weight: normal;
  white-space: pre-wrap;
}

.my-ant-modal {
  border-radius: 3px;
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-header {
      border-bottom: none;
      background: #338DE0;
    .ant-modal-title {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.15px;
      color: #FFFFFF;
    }
  }
  .ant-modal-close {
      svg {
      fill: #fff;
    }
  }
}

.ant-row .ant-form-item {
  margin-bottom: 20px;
}
.my-ant-row-grid {
  display: grid;
  row-gap: 7px!important;
}
.row-title-3 {
  font-weight: 500;
  font-size: 16px!important;
  line-height: 19px;
  letter-spacing: 0.15px;
  color: #121F3E;
  margin: 0!important;
  width: fit-content;
}

.my-ant-input {
  border-radius: 8px!important;
  padding: 13px 15px;
  line-height: 20px;
  min-height: 48px!important;
  margin: 0!important;
}
.my-ant-input-select {
  .ant-select-selector {
    border-radius: 8px!important;
    padding: 5px 15px 5px 11px;
    line-height: 20px;
    min-height: 48px!important;
    margin: 0!important;
  }
}
.my-ant-row-grid,
.project-type-element-item {
  .ant-btn.ant-btn-link {
    border: 1px solid #338DE0;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 15px!important;
    span {
      color: #338DE0;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.15px;
    }
  }
}

.my-ant-row-grid {
  .ant-picker {
    border-radius: 8px!important;
    padding: 10px 15px 10px 11px!important;
    line-height: 20px;
    margin: 0!important;
    height: 48px!important;
  }
}
.my-ant-input-number {
  padding: 4px 0;
  border-radius: 8px!important;
  overflow: hidden;
}

.project-type-grid {
  display: grid;
  row-gap: 7px!important;
  column-gap: 30px;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin-bottom: 20px;
  .ant-switch {
    background-color: #fff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    .ant-switch-handle::before {
      background-color: #E1E8EF;
    }
  }
  .ant-switch-checked {
    background-color: #fff;
    .ant-switch-handle::before {
      background-color: #28C397;
    }
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .project-type-element-items {
    grid-column: 1 / 3;
    display: grid;
    row-gap: 7px;
    .project-type-element {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
      .project-type-element-item {
        display: grid;
        row-gap: 7px!important;
      }
    }
  }
  .ant-picker {
    border-radius: 8px!important;
    padding: 10px 15px 10px 11px!important;
    line-height: 20px;
    margin: 0!important;
    height: 48px!important;
    width: 10em!important;
  }
  .my-ant-input-number {
    width: 10em!important;
    border-radius: 8px!important;
    padding: 13px 15px;
    line-height: 20px;
    min-height: 48px!important;
    margin: 0!important;
  }
}

.my-ant-modal.contragent {
  .search-field {
    background-color: #338DE0;
    width: 100%;
    padding: 15px 30px;
    .ant-form-item {
      margin-bottom: 0;
      width: 100%;
    }
    input {
      width: 100%;
      outline: none;
      border: none;
      background-color: transparent;
      border: 1px solid rgba(225, 232, 239, 0.5);
      box-sizing: border-box;
      border-radius: 8px;
      padding: 15px 15px 14px 54px;
      font-size: 16px;
      line-height: 19px;
      color: #fff;
      background-repeat: no-repeat;
      background-position: center left 18px;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5 0C8.22391 0 9.87721 0.684819 11.0962 1.90381C12.3152 3.12279 13 4.77609 13 6.5C13 8.11 12.41 9.59 11.44 10.73L11.71 11H12.5L17.5 16L16 17.5L11 12.5V11.71L10.73 11.44C9.59 12.41 8.11 13 6.5 13C4.77609 13 3.12279 12.3152 1.90381 11.0962C0.684819 9.87721 0 8.22391 0 6.5C0 4.77609 0.684819 3.12279 1.90381 1.90381C3.12279 0.684819 4.77609 0 6.5 0ZM6.5 2C4 2 2 4 2 6.5C2 9 4 11 6.5 11C9 11 11 9 11 6.5C11 4 9 2 6.5 2Z' fill='white' fill-opacity='0.5'/%3E%3C/svg%3E%0A");
      &::placeholder {
        color: rgba(255, 255, 255, 0.5);;
      }
    }
  }
  
  .data-field {
    display: grid;
    grid-template-columns: 25% 75%;
    .ant-checkbox-wrapper {
      margin-left: 7px!important;
    }
  }
}
.comments-block {
  width: calc(100% - 320px);
  .ant-list {
    width: 100%;
    .ant-list-header {
      display: none;
    }
    .ant-list-items {
      li {
        width: calc(100% - 230px);
        .ant-comment {
          width: 100%;
          .ant-comment-inner {
            padding: 10px 0;
          }
          .ant-comment-content {
            width: 100%;
            background: rgba(51, 141, 224, 0.08);
            border: 1px solid #F2F6FF;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 10px;
            .ant-comment-content-author-name {
              font-weight: 500;
              font-size: 14px;
              line-height: 19px;
              letter-spacing: 0.15px;
              color: #000000;
            }
            .ant-comment-content-detail {
              text-align: start;
              font-size: 14px;
              line-height: 19px;
              letter-spacing: 0.15px;
              color: #121F3E;
            }
            .ant-comment-actions {
              display: flex;
              gap: 7px;
              li {
                width: fit-content!important;
                span {
                  font-size: 12px;
                  cursor: pointer;
                  color: #A0A0A0;
                  transition: all .2s ease;
                  &:hover {
                    color: #000;
                  }
                }
              }
              width: fit-content!important;
            }
          }
        }
      }
    }
  }
}

.task_view_modal {
  .ant-modal-header {
    padding: 13px 16px;
    background: #338DE0;
    .task_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 50px;
      flex-wrap: nowrap;
      .myProjectsButtons_edit:not(svg),
      .myProjectsButtons_delete:not(svg) {
        padding: 10px;
        box-sizing: border-box;
        width: 40px;
        height: 40px;
      }
      .task_title {
        color: #fff;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.15px;
      }
    }
  }
  .formLabelBold {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.15px;
    color: #3C4E77;
  }
  .anticon.anticon-user.avatarCanban {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: flex;
    padding-top: 5px;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: #ccc;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 50%;
    svg {
      width: 18px;
      height: 18px;
    }
  }
  .ant-comment-avatar {
    cursor: auto;
  }
  .comment_controllers {
    display: grid;
    row-gap: 15px!important;
    input {
      outline: none;
      border: 1px solid #E1E8EF;
      box-sizing: border-box;
      border-radius: 8px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.15px;
      padding: 13px 15px;
      transition: all .2s ease;
      &:focus {
        border: 1px solid #338DE0;
      }
      &::placeholder {
        color: #96A0B5;
      }
    }
    button {
      width: fit-content;
      background: #338DE0;
      border-radius: 8px;
      padding: 10px 30px;
      border: none;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.15px;
      color: #FFFFFF;
      cursor: pointer;
      transition: all .2s ease;
      &:hover {
        opacity: .9;
      }
    }
  }
}
.my-ant-input-select{
  margin-top:10px;
}
.data-field__h1::before{
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background-color: rgba(0, 0, 0, 0.06);
    transform: translateY(-50%);
    transition: background-color 0.3s;
    content: '';
}
.ant-checkbox-wrapper{
  justify-content: space-between;
  margin-left: 20px;
}
div[class='contragent']{
  width: 80%;
}

.projectsList {
  .ant-list-item-meta-title {
    margin-right: 70px;
    width: auto;
  }
  .ant-list-item-meta-content {
    display: grid;
    grid-template-columns: 33% 1fr;
  }
  h2 {
    color: #338DE0;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  h3 {
    font-size: 14px;
  }
  h5 {
    font-size: 12px;
  }
}
.download-report {
  display: block;
  width: fit-content;
  margin-top: 30px;
  background: rgba(51, 141, 224, 0.1);
  border-radius: 8px;
  padding: 13px 56px 13px 20px;
  text-transform: uppercase;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.75758 0L7.24242 0L7.24242 11.0909L3.07576 6.92424L2 8L8 14L14 8L12.9242 6.92424L8.75758 11.0909V0Z' fill='%23338DE0'/%3E%3Crect y='17' width='1.5' height='16' transform='rotate(-90 0 17)' fill='%23338DE0'/%3E%3C/svg%3E%0A");
  background-position: right 20px center;
  background-repeat: no-repeat;
}