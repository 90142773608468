.fotgetBtn {
  font-size: 20px;
  text-decoration: underline;
  float: left;
  margin-left: 30px;
  color: #1890ff;

  &:hover {
    cursor: pointer;
  }
}