.investment-stats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  .title {
    grid-column: 1 / 3;
    padding: 0!important;
    text-align: start;
    h1 {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.15px;
      text-transform: uppercase;
      color: #338DE0;
    }
    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.15px;
      color: #080A1C;
      margin: 0;
    }
  }
  &__item {
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
.create-invest-project {
  .title {
    h1 {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.15px;
      text-transform: uppercase;
      color: #080A1C;
      text-align: start;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.15px;
      color: #080A1C;
      text-align: start;
    }
  }
  .steps {
    width: 300px;
    margin-bottom: 25px;
    .ant-steps-item {
      padding-left: 0!important;
      .ant-steps-item-icon {
        cursor: pointer;
      }
      .ant-steps-item-title::after {
        left: -7px;
        top: 12px;
      }
    }
    .ant-steps-item-wait {
      .ant-steps-item-icon {
        pointer-events: none;
        cursor: default;
      }
      svg circle {
        stroke: #96A0B5;
      }
      svg circle:first-child {
        fill: #96A0B5;
      }
    }
  }
  section {
    display: flex;
    flex-direction: column;
    & > div {
      width: 100%;
    }
    &.with-cart {
      width: 70%;
    }
    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 0.15px;
      color: #121F3E;
      text-align: start;
      margin-bottom: 20px;
      display: block;
    }

    .grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(240px, .25fr));
      gap: 20px;

      &.categories {
        grid-template-columns: repeat(2, 1fr);
        div {
          height: 300px;
        }
      }
      &.types {
        margin-bottom: 50px;
      }
      .image-card {
        background-color: #080A1C;
      }
      .image-card, div {
        border-radius: 8px;
        overflow: hidden;
        height: 145px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-direction: column;
        cursor: pointer;

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          transition: all .2s ease;
      }
        img:hover {
          opacity: .4;
        }
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.15px;
          color: #fff;
          z-index: 1;
        }
      }
    }
    .kinds, aside {
      div {
        height: 100%;
        overflow: visible;
        background-color: transparent;
        flex-direction: column;
        cursor: default;
        border: 1px solid #C6D1DD;
        img:hover {
          opacity: 1;
        }
        &.kind-data {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          width: auto;
          padding: 0;
        }
        div {
          position: relative;
          border-radius: 0%;
          flex-direction: row;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.15px;
          color: #96A0B5;
          padding: 9px 7px;
          border: none;
          &.name {
            grid-column: 1 / 4;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.15px;
            color: #000000;
            text-align: start;
          }
          &.temperature, &.size {
            svg {
              width: 22px;
              height: 22px;
              path {
                fill: #96A0B5;
              }
            }
          }
          &.price {
            grid-column: 1 / 4;
            display: flex;
            justify-content: space-between;
            padding-right: 10px;
            button {
              border: none;
              background: none;
              cursor: pointer;
              svg path {
                transition: all .2s ease;
              }
              &:hover {
                svg path {
                  fill: #338DE0;
                }
              }
            }
          }
          &.beds {
          }
        }
      }
    }
    aside {
      width: 30%;
      position: absolute;
      right: 0;
      top: 0;
      min-height: calc(100vh - 80px);
      height: 100%;
      background: #fff;
      overflow: scroll;
      padding: 20px;
      padding-right: 0;
      display: flex;
      flex-direction: column;
      gap: 25px;
      > div {
        display: flex;
        flex-direction: row;
        min-height: 108px;
        max-height: 108px;
        img {
          width: 108px;
          object-fit: cover;
        }
        .kind-data {
          div {
            display: flex;
            align-items: center;
            padding: 0 5px;
          }
          .price {
            padding-right: 15px;
            button {
              transition: all .2s ease;
              svg path {
                transition: all .2s ease;
              }
            }
            &:hover {
              button {
                color: #FF575F;
              }
              svg path {
                fill: #FF575F;
              }
            }
          }
        } 
      }
      .go-to-basket {
        background: #338DE0;
        padding: 13px 50px;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.15px;
        text-transform: uppercase;
        color: #FFFFFF;
        border: none;
        border-radius: 8px;
        cursor: pointer;
      }
    }
  }
  .success-button {
    background: #28C397;
    border: #28C397;
  }
  .cancel-button {
    color: rgba(31, 35, 46, 0.5);
  }
  div[role="alert"] {
    text-align: start;
    padding-left: 15px;
  }
}
.basket {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 25px;
  &-item {
    border: 0.5px solid #96A0B5;
    padding: 20px;
    &-top {
      display: flex;
      gap: 30px;
      margin-bottom: 20px;
      img {
        width: 350px;
        height: 145px;
        object-fit: cover;
      }
      &-right {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .basket-item-data {
          display: flex;
          flex-direction: column;
          span {
            text-align: start;
            font-size: 16px;
            line-height: 35px;
            letter-spacing: 0.15px;
            span {
              color: #96A0B5;
            }
          }
          .name {
            font-size: 20px;
            line-height: 20px;
            letter-spacing: 0.15px;
            color: #000000;
            margin-bottom: 15px;
          }
        }
      }
    }
    &-count-and-price {
      display: flex;
      flex-direction: column;
      gap: 30px;
      height: 100%;
      justify-content: center;
      span:first-child {
        font-weight: 500;
        font-size: 24px;
        line-height: 20px;
        letter-spacing: 0.15px;
        color: #000000;
      }
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.15px;
        color: #000000;
      }
    }
    &-description {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.15px;
      color: #000000;
      text-align: start;
    }
  }
}

.required-buildings {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 25px;
  .required-building {
    padding: 20px;
    display: flex;
    gap: 30px;
    border: 0.5px solid #96A0B5;
    img {
      object-fit: cover;
      height: 175px;
      width: 350px;
    }
    div {
      display: flex;
      flex-direction: column;
      gap: 10px;
      h4 {
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.15px;
        color: #000000;
      }
      p {
        text-align: start;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.15px;
        color: #000000;
      }
    }
  }
}

.ant-upload-list-item-image {
  object-fit: cover;
}
.my-ant-textarea {
  border-radius: 8px!important;
  margin-bottom: 0!important;
  &:focus {
    border: 1px solid #1890ff!important;
  }
}

.investment-projects-list {
  .ant-avatar {
    width: 70px;
    height: 70px;
  }
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.15px;
    color: #338DE0;
  }
  .project-data {
    display: grid;
    // grid-template-columns: .6fr 1fr .8fr .6fr;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding-left: 30px;
    &-item {
      display: flex;
      flex-direction: column;
      gap: 6px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.15px;
      color: #338DE0;
      text-align: start;
      h3 {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.15px;
        color: #96A0B5;
        text-align: start;
        margin-bottom: 0;
      }
      .status {
        position: relative;
        padding-left: 20px;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
        &.yellow::before {
          background: #FCC865;
        }
        &.red::before {
          background: #FF575F;
        }
        &.green::before {
          background: #28C397;
        }
        &.gray::before {
          background: #96A0B5;
        }
      }
    }
  }
}

.investment-project-page {
  h2 {
    text-align: start;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #121F3E;
  }
  .project-data {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .title {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.15px;
      color: #96A0B5;
      text-align: start;
      span {
        color: #000;
      }
    }
  }
  p {
    text-align: start;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.15px;
    color: #121F3E;
  }
  .project-footer {
    gap: 15px;
    .gray-button {
      background: #8B96A5;
      border: #8B96A5;
    }
  }
}